export type viewmode = 'grid' | 'list';

export interface ISettings {
  page: 'channel' | 'dashboard';
  channel: Channel;
  selectedBrief?: number;
  from?: User;
}

export enum ObjectTypes {
  user,
  tag,
  brief,
  channel
}

export enum GenderTypes {
  unspecified,
  male,
  female
}

interface From {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  timestamp: number;
}

export interface Translation {
  uuid: string;
  title: string;
  text: string;
  audio: string;
  languageCode: string;
  created_at: number;
  updated_at: number;
  master: boolean;
  languageImage: string;
  uuidVoice: string;
  uuidBackgroundAudio?: string;
}

export interface Brief {
  uuid: string;
  created_at: number;
  updated_at: number;
  position: number;
  status: number;
  content: Content;
  translations: Translation[];
  channel?: Channel;
  backgroundAudio?: BgAudio;
}

export interface BriefCreate {
  uuidChannel: string;
  image: string;
  sourceText: string;
  link: string;
  summarySize: number;
  languageCode: string;
  uuidVoice: string;
  audio: string;
  title: string;
  text: string;
}

export interface BriefSpace {
  uuid: string;
  company: string;
  subdomain: string;
  description: string;
  apiKey: string;
  image: string;
  created_at: number;
}

export enum PusblishStatus {
  Offline,
  Online
}
export interface Channel {
  id?: number;
  uuid?: string;
  position?: number;
  idUser?: number;
  title?: string;
  description?: string;
  image?: string;
  status?: PusblishStatus;
  lastUpdate?: number;
  timestamp?: number;
  backgroundColor?: string;
  author?: User;
  briefs?: Brief[];
  tags?: Tags[];
  followers?: number;
  isFollowed?: boolean;
  created_at?: number;
  updated_at?: number;
  space?: BriefSpace;
  rule?: RuleEnum;
}

export enum RuleEnum {
  GUEST,
  CONSUMER,
  COEDITOR,
  ADMIN
}
//@ts-ignore
export interface ChannelOrBrief extends Brief, Channel { }

export interface Response {
  success: boolean;
  from: From;
  channel: Channel;
}

export interface Content {
  summary: string;
  title: string;
  description: string;
  image: string;
  domain: string;
  favicon: string;
  size: number;
  link?: string;
}

export interface SupportedLanguage {
  code: string;
  name: string;
  text: string;
  image: string;
}

export interface SupportedVoice {
  id: number;
  uuid: string;
  name: string;
  nickname: string;
  languageCode: string;
  gender: number;
  provider: number;
  audioPreview: string;
  image: string;
  premium: number;
}

export enum SocialProvider {
  brief,
  facebook,
  google
}

export interface User {
  id: number;
  uuid: string;
  accessToken: string;
  username?: any;
  email: string;
  password: string;
  password_reset_token?: any;
  firstName: string;
  lastName: string;
  bio?: any;
  image?: string;
  pushToken?: any;
  status: number;
  created_at: number;
  updated_at: number;
  channelsCount?: number;
  briefsCount?: number;
  rule?: RuleEnum;
}
export interface TokenUser {
  deviceToken: string;
  user: User;
  Authorization: string;
}
export enum ChannelStatus {
  private,
  public,
  archived
}

export const ChannelStatusOptions = [
  {
    label: 'private',
    value: ChannelStatus.private
  },
  {
    label: 'public',
    value: ChannelStatus.public
  },
  {
    label: 'archived',
    value: ChannelStatus.archived
  }
];
export enum BriefSource {
  weblink,
  text
}

export const ChannelStatusLabel = ['Private Network', 'Everyone can see', 'Archived'];

export interface Tags {
  id?: any,
  name: string,
  isInterest?: number
}

export type Interests = string[];

interface PaginationResponse<T> {
  status: number;
  success: boolean;
  _links: Links;
  _meta: Meta;
}

interface Meta {
  totalCount: number;
  pageCount: number;
  currentPage: number;
  perPage: number;
}

interface Links {
  self: Self;
  first: Self;
  last: Self;
}

interface Self {
  href: string;
}

export interface AudioPreview {
  languageCode: string;
  uuidVoice?: string;
  audio?: string;
  title: string;
  text: string;
  uuidBackgroundAudio?: string;
}

export interface BgAudio {
  uuid: string;
  name: string;
  audio: string;
}


export interface ChannelAnalitic {
  count: number;
  days: AnaliticDay[];
}

interface AnaliticDay {
  timestamp: any;
  count: number;
}
