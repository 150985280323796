import upload, { RcFile } from 'antd/lib/upload';
import { CreateNewBrief, NewBrief } from 'constants/brief.helpers';
import { update } from 'lodash';
import {
  Brief,
  BriefSource,
  Channel,
  SupportedVoice,
  SupportedLanguage,
  Content,
  AudioPreview,
  Translation,
  PusblishStatus,
  BgAudio
} from 'store/types';
import API_CALL, { getResponse } from './API';

const url = 'business/briefs';
export const ServiceBriefs = {
  get: async ({ briefUUID, headers = {} }) => {
    return API_CALL({
      url: `${url}/${briefUUID}`,
      headers
    }).then((response) => {
      const brief = getResponse<Brief>(response, 'brief', {});
      return brief;
    });
  },
  getContent: async ({ link, size = 1.0 }: { link: string; size?: number }) => {
    return API_CALL({
      method: 'POST',
      url: url + '/get-content',
      data: {
        link,
        size
      }
    }).then((response) => {
      const Summary = getResponse<Content>(response, 'content', {});
      return Summary;
    });
  },

  getSpeakers: async (text: string) => {
    return API_CALL({
      method: 'POST',
      url: url + '/get-speakers',
      data: {
        text
      }
    })
      .then((response: { voices: SupportedVoice[]; languageCode: string }) => {
        const { voices, languageCode } = response;
        return { voices, languageCode };
      })
      .catch((e) => {
        console.warn(e);
        return { voices: [], languageCode: '' };
      });
  },

  supportedLanguages: async () => {
    return API_CALL({
      url: 'business/supported-languages'
    }).then((response) => {
      const supportedLanguages = getResponse<SupportedLanguage[]>(
        response,
        'supportedLanguages',
        {}
      );
      return supportedLanguages;
    });
  },

  supportedVoices: async (lang: string) => {
    return API_CALL({
      url: 'business/supported-voices/' + lang
    }).then((response) => {
      const supportedVoices = getResponse<SupportedVoice[]>(response, 'supportedVoices', {});
      return supportedVoices;
    });
  },

  create: async (brief: CreateNewBrief) => {
    return API_CALL({
      //baseURL : API_URL + url + "loginCredentials"
      method: 'POST',
      url,
      data: brief
    }).then((response) => {
      const briefCreated = getResponse<Brief>(response, 'brief');
      return briefCreated;
    });
  },

  update: async (brief: CreateNewBrief, briefUUID: string) => {
    return API_CALL({
      //baseURL : API_URL + url + "loginCredentials"
      method: 'PUT',
      url: `${url}/${briefUUID}`,
      data: brief
    }).then((response) => {
      const briefCreated = getResponse<Brief>(response, 'brief');
      return briefCreated;
    });
  },

  delete: async ({ briefUUID, headers = {} }) => {
    return API_CALL({
      method: 'DELETE',
      url: `${url}/${briefUUID}`,
      headers
    });
  },

  preview: async ({
    audioPreview,
    headers = {}
  }: {
    audioPreview: AudioPreview;
    headers?: any;
  }) => {
    return API_CALL({
      method: 'POST',
      url: `${url}/preview`,
      data: audioPreview,
      headers
    }).then((response) => {
      const preview = getResponse<string>(response, 'preview');
      return preview;
    });
  },

  translation: async ({
    briefUUID,
    audioTranslation,
    headers = {}
  }: {
    briefUUID: string;
    audioTranslation: AudioPreview;
    headers?: any;
  }) => {
    return API_CALL({
      method: 'POST',
      url: `${url}/${briefUUID}/translations`,
      data: audioTranslation,
      headers
    }).then((response) => {
      const translation = getResponse<Translation>(response, 'translation');
      return translation;
    });
  },

  updateTranslation: async ({
    briefUUID,
    translationUUID,
    audioTranslation,
    headers = {}
  }: {
    briefUUID: string;
    translationUUID: string;
    audioTranslation: AudioPreview;
    headers?: any;
  }) => {
    return API_CALL({
      method: 'PUT',
      url: `${url}/${briefUUID}/translations/${translationUUID}`,
      data: audioTranslation,
      headers
    }).then((response) => {
      const translation = getResponse<Translation>(response, 'translation');
      return translation;
    });
  },

  deleteTranslation: async ({
    briefUUID,
    translationUUID,
    headers = {}
  }: {
    briefUUID: string;
    translationUUID: string;
    headers?: any;
  }) => {
    return API_CALL({
      method: 'DELETE',
      url: `${url}/${briefUUID}/translations/${translationUUID}`,
      headers
    }).then((response) => {
      return response;
    });
  },

  upload: async ({
    file,
    type = 'image',
    onProgress = null,
    headers = {}
  }: {
    file: string | Blob | RcFile;
    onProgress?: any;
    type?: 'image' | 'audio';
    headers?: any;
  }) => {
    const formData = new FormData();
    formData.append(type, file);

    return API_CALL({
      url: `/business/upload/` + type,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        console.log('onUploadProgress', progressEvent.loaded, progressEvent.total);
        onProgress && onProgress(progressEvent);
      },

      data: formData
    }).then((response) => {
      console.log('response', response);
      debugger;
      return response;
    });
  },

  order: async ({ briefUUID, position }: { briefUUID: string; position: number }) => {
    return API_CALL({
      //baseURL : API_URL + url + "loginCredentials"
      url: `business/briefs/${briefUUID}/order`,
      method: 'POST',
      data: {
        position
      }
    });
  },

  translate: async ({
    briefUUID,
    lang,
    headers = {}
  }: {
    briefUUID: string;
    lang: string;
    headers?: any;
  }) => {
    return API_CALL({
      method: 'GET',
      url: `${url}/${briefUUID}/translations/${lang}`,
      headers
    }).then((response) => {
      const translate = getResponse<Translation>(response, 'translation');
      return translate;
    });
  },

  status: async ({ briefUUID, status }: { briefUUID: string; status: PusblishStatus }) => {
    const published = status === PusblishStatus.Online ? 'on' : 'off';
    return API_CALL({
      url: `business/briefs/${briefUUID}/publish/${published}`,
      method: 'POST'
    }).then((response) => {
      const briefCreated = getResponse<Brief>(response, 'brief');
      return briefCreated;
    });
  },

  getBgAudios: async () => {
    return API_CALL({
      method: 'GET',
      url: 'business/background-audios/list'
    }).then((response: { backgroundAudios: BgAudio[] }) => {
      return response.backgroundAudios;
    });
  },

  listened: async (briefUUID: string) => {
    //console.count("Listened " + briefUUID)
    return API_CALL({
      url: `business/briefs/${briefUUID}/listened`,
      method: 'POST'
    }).then((response: any) => {

      return response
    });
  }
};
export default ServiceBriefs;
