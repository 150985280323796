import { Dispatch } from 'react';
import {
  AudioPreview,
  BgAudio,
  Brief,
  Channel,
  Content,
  SupportedLanguage,
  SupportedVoice,
  Translation
} from 'store/types';
import { State } from 'zustand';
import { ModalConfirmAsync } from './Notifications';

export const getBriefTitle = (brief: Brief) => {
  return brief?.translations?.[0]?.title || brief.uuid;
};

export const getBriefDescription = (brief: Brief) => {
  return brief?.translations?.[0]?.text || '';
};

export type BriefAction =
  | { type: 'setBrief'; brief: Partial<NewBrief> }
  | { type: 'setTranslation'; translation: Partial<Translation> }
  | { type: 'setVoice'; voice: SupportedVoice, audio?: string }
  | { type: 'setMp3'; audio: string }
  | { type: 'setBgAudio'; bgAudio: BgAudio }
  | { type: 'prop'; prop: keyof BriefState; value: any }
  | { type: 'content'; payload: Content }
  | { type: 'toggleLoading'; loading: string };

export interface BriefState {
  brief: NewBrief;

  summarySize: number;

  loading?: boolean;
  loadingText?: string;
  audioPreview?: any;
}

export type EditBriefAction =
  | {
    type: 'setBrief';
    payload: {
      brief: Brief;
      voice: SupportedVoice;
      currentTranslation: Partial<Translation>;
      audioPreview: AudioPreview;
    };
  }
  | { type: 'prop'; prop: keyof EditBriefState; value: any }
  | { type: 'setCurrentTranslation'; translation: Partial<Translation> }
  | { type: 'editCurrentTranslation'; translation: Partial<Translation> }
  | { type: 'setVoice'; voice: SupportedVoice }
  | { type: 'setNewTranslation'; translation: Translation }
  | {
    type: 'setAudioPreview';
    payload: {
      audio: string;
      audioPreview: AudioPreview;
    };
  }
  | { type: 'toggleLoading'; loading: { loading: boolean; loadingText: string } };

export interface EditBriefState {
  brief: Brief;
  currentTranslation: Partial<Translation>;
  audio?: string;
  voice?: SupportedVoice;
  loading?: boolean;
  loadingText?: string;
  audioPreview?: AudioPreview;
}

type AsyncDispatch<T> = Dispatch<T | Promise<T>>;

export const wrapAsync = <T>(dispatch: Dispatch<T>): AsyncDispatch<T> => {
  return (action: T | Promise<T>) => {
    if (action instanceof Promise) {
      return action.then(dispatch);
    }
    return dispatch(action);
  };
};

export interface TempBrief extends Partial<NewBrief> { }

export interface CreateNewBrief {
  uuid?: string;
  uuidChannel: string;
  uuidVoice?: string;
  audio?: string;
  languageCode: string;
  title: string;
  text: string;
  content: Content;
  uuidBackgroundAudio?: string;
  backgroundAudio?: BgAudio;
}

export interface NewBrief {
  uuid?: string;
  uuidChannel: string;
  uuidVoice: string;
  languageCode: string;
  content: Partial<Content>;
  translations: Partial<Translation>[];
  channel?: Channel;
  uuidBackgroundAudio?: string;
  backgroundAudio?: BgAudio;
}

export const createEmptyBrief = ({
  uuidChannel,
  content,
  channel = {}
}: Partial<NewBrief>): NewBrief => {
  const newBrief: NewBrief = {
    uuidChannel,
    languageCode: '',
    channel,
    uuidVoice: '',
    content,
    translations: [
      {
        title: '',
        text: ''
      }
    ]
  };
  return newBrief;
};
