import { BRIEF_API_URL } from 'constants/global';
import ROUTES from 'constants/Routes';
import fetchJson from 'lib/fetchJson';
import { useRouter } from 'next/router';

import { useEffect, useMemo, useState } from 'react';
import API_CALL from 'services/API';
import LocalStorage from 'services/LocalStorage';
import useSWR from 'swr';
import { User } from './types';
import { GlobalState, useStore } from './zustandProvider';
import nookies, { parseCookies, destroyCookie } from 'nookies';
import ServiceAuthentication from 'services/Auth';

interface UserSession extends GlobalState {
  loading: boolean;

  isLoggedIn: boolean;
  signOut: any;
}
export const useSessionUser = (): UserSession => {
  //const sessionUser = undefined
  const { data: { user: sessionUser } = { user: undefined }, mutate: mutateUser } = useSWR(
    '/api/user'
  );

  const [loading, setLoading] = useState(false);

  const { me, user, setMe, channel, setUser, setChannel, setOwnChannel, setIsCoeditor, } = useStore();
  const router = useRouter();

  useEffect(() => {
    if (sessionUser && !me) {
      const _me = sessionUser as User;
      setMe(_me);
      LocalStorage.setItem('me', _me);
    } else if (!sessionUser && !me) {
      //debugger;
      autoLogin();
    }
  }, [sessionUser, me]);



  const autoLogin = async () => {
    //debugger;
    console.log('🚀 ~ autoLogin');
    const cookies = parseCookies();
    console.log({ cookies });

    if (cookies.Authorization) {
      const user = await ServiceAuthentication.profile();
      //set user into session
      await fetchJson('/api/user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: { user }
      });

      setMe(user);
      LocalStorage.setItem('me', user);
    }
  };


  const ownChannel = useMemo(() => {
    let isMyPage = false;
    if (channel?.uuid) isMyPage = channel?.author?.uuid === me?.uuid;

    setOwnChannel(isMyPage);
    return isMyPage;
  }, [me, channel, user]);

  const isCoeditor = useMemo(() => {
    let _isCoeditor = false;
    if (channel?.uuid) _isCoeditor = me?.uuid && channel?.rule > 1;

    setIsCoeditor(_isCoeditor);
    return _isCoeditor;
  }, [me, channel?.rule, user]);

  const isLoggedIn = useMemo(() => (me && me.uuid ? true : false), [me]);

  const signOut = async () => {
    await logout();
    await fetchJson('/api/logout', { method: 'POST' });
    LocalStorage.clear();
    setMe(undefined);
    LocalStorage.removeItem('me');
    router.push(ROUTES.auth.login);

    destroyCookie(null, 'subdomain', { path: '/' });
    destroyCookie(null, 'deviceToken', { path: '/' });
    destroyCookie(null, 'Authorization', { path: '/' });
  };

  const logout = async () => {
    const url = `${BRIEF_API_URL}/business/auth/logout`;

    return API_CALL({
      method: 'POST',
      url
    })
      .then((response) => {
        console.log('response', response);
        //debugger;
      })
      .catch((err) => {
        console.error(err);
        //debugger;
      });
  };

  const setMyUser = async (me) => {
    destroyCookie(null, 'subdomain');
    await fetchJson('/api/user', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { user: me }
    });

    setMe(me);
  };
  //@ts-ignore
  return {
    loading,
    me,
    ownChannel,
    channel,
    setMe: setMyUser,
    setOwnChannel,
    setUser,
    setChannel,
    isCoeditor,
    setIsCoeditor,
    isLoggedIn,
    //@ts-ignore
    user,
    signOut
  };
};
