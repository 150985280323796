import { DownOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import UserAvatar from 'components/user/UserAvatar';
import { getFullName, getInitials } from 'constants/helpers';
import React, { FC, useEffect, useState } from 'react';
import { User } from 'store/types';
import CommonAvatar from './CommonAvatar';

interface UserAvatarProps {
  user: User;
  showCaret?: boolean;
}
const TopbarUserAvatar: FC<UserAvatarProps> = ({ user, showCaret = false }) => {
  return (
    <Space>
      <UserAvatar user={user} />
      <div className=" userFullName">{getInitials(user)}</div>
      {showCaret && <DownOutlined style={{ color: 'white' }} />}
    </Space>
  );
};
export default TopbarUserAvatar;
