import { Button, Card, Layout, Popover, Space } from 'antd';
import { Debug } from 'components/commons/DebugComponent';
import JsonLog from 'components/commons/JsonLog';
import TopbarUserAvatar from 'components/commons/TopbarUserAvatar';
import UserPopover from 'components/user/UserPopover';
import ROUTES from 'constants/Routes';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import ServiceAuthentication from 'services/Auth';
import { useSessionUser } from 'store/hooks';
import Logo from './Logo';

const { Header, Content, Footer } = Layout;

const Breadcrumbs = dynamic(import('components/commons/BreadCrumbs'), {
  ssr: false
});

interface TopBarProps { }
const TopBar: FC<TopBarProps> = () => {
  const { me } = useSessionUser();

  const { push, route } = useRouter();

  return (
    <React.Fragment>
      <Header id="topBar">
        <div className="headerContent">
          <div id="leftHeader">
            <div onClick={() => push('/')}>
              <Logo color="white" />
            </div>
            {route?.includes('/channels/[channelUUID]') && <Breadcrumbs />}
          </div>

          <div id="rightHeader">
            {me ? (
              <Popover
                placement="bottomRight"
                className="UserPopover"
                trigger="click"
                overlayClassName="userPopover"
                autoAdjustOverflow
                content={<UserPopover />}>
                <div>
                  <TopbarUserAvatar user={me} showCaret />
                </div>
              </Popover>
            ) : (
              <Button type="default" className="bordered small white">
                <Link href={ROUTES.auth.login}>Login</Link>
              </Button>
            )}
          </div>
        </div>
      </Header>
    </React.Fragment>
  );
};
export default TopBar;

export const DebugMenu = () => {
  const store = useSessionUser();
  const { me, ownChannel, signOut } = store;

  const getProfile = () => {
    ServiceAuthentication.profile()
      .then((response) => {
        console.log('response', response);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Debug>
      <div id="debugMenu">
        <Space align="start">
          <Link href={'/'}>HOME</Link>
          <Link href={ROUTES.getUserPage(me)}>MY CHANNEL</Link>
          <Link href="/user/2ef015d5-5342-4913-8034-235a9df7a3f9">User</Link>

          <Link href="/user/2ef015d5-5342-4913-8034-235a9df7a3f9/a61188cd-b991-433b-818c-1b3cb6adec14">
            Channel
          </Link>
          <Link href={ROUTES.auth.login}>LOGIN</Link>

          <Link href="/private">private</Link>
          <div>{ownChannel ? <div>ownChannel</div> : <div>other channel</div>}</div>
          <Button type="primary" onClick={() => me && signOut()}>
            {me ? <div> {me.uuid} (LogOut)</div> : <div>NOT</div>}
          </Button>
          <Button type="primary" onClick={() => getProfile()}>
            profile
          </Button>
          <Card>
            <JsonLog src={store} />
          </Card>
        </Space>
      </div>
    </Debug>
  );
};
