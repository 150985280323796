import _, { debounce, replace } from 'lodash';
import { ChannelStatus, User } from 'store/types';
import sanitizeHtml from "sanitize-html";
const equal = require('deep-equal');

//export const RegexUrl = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
export const RegexUrl = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const checkValidUrl = (url: string) => {
  if (!url) return false;
  const expression = RegexUrl;
  const regex = new RegExp(expression);
  return url.match(regex) ? true : false;
};

export const getStatusByValue = (status: ChannelStatus) => {
  let statuText = '';

  switch (status) {
    case ChannelStatus.archived:
      statuText = 'Archived';

      break;
    case ChannelStatus.private:
      statuText = 'Private';

      break;
    case ChannelStatus.public:
      statuText = 'Published';

      break;
  }

  return statuText;
};

export const areEqual = (obj1, obj2) => {
  const areEquals = equal(obj1, obj2);
  console.log('areEqual -> obj1, obj2', obj1, obj2, areEquals);
  return areEquals;
};

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const cleanNulls = <T>(data): T => {
  return _.pickBy(data, _.identity) as T;
};

export const ScrollTo = (elementID, containerId = '') => {
  console.log('TCL: ScrollTo -> elementID', elementID);

  // document.getElementById(elementID).scrollIntoView({ behavior: 'smooth', block: 'center' });
  const element = document.getElementById(elementID.replace('#', ''));

  if (!element) return false;

  const headerOffset = 100;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - headerOffset;

  const parentScroll = containerId
    ? document.getElementById(containerId.replace('#', ''))
    : document.body;
  //debugger;
  if (element && parentScroll) {
    console.log('TCL: ScrollTo -> elementID 2', elementID);
    parentScroll.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  /*scroller.scrollTo(elementID, {
    duration: 1500,
    delay: 100,
    smooth: true,
    offset: -100, // Scrolls to element + 50 pixels down the page
    containerId
  })*/
};

export const array_move = (data: any[] = [], old_index, new_index) => {
  const elemToMove = data[old_index];

  data.splice(old_index, 1);
  data.unshift(elemToMove);

  return data || [];
};

export const getInitials = (user: User | undefined) => {
  const matches = `${user?.firstName} ${user?.lastName}`.trim().match(/\b(\w)/g);
  const initials = matches?.join(''); // JSON

  return initials?.toUpperCase().substr(0, 2);
};

export const getFullName = (user: User | undefined) => {
  const fullName = `${user?.firstName || user.username} ${user?.lastName || ''}`.trim();

  return fullName;
};

export function asyncDebounce(func, wait) {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    });
}


const sanitizeOptions = {
  allowedTags: [],
  allowedAttributes: {}
}
export const cleanText = (text: string, isTextarea = false) => {

  const cleaned = sanitizeHtml(text, sanitizeOptions)
  console.log({ text, cleaned })
  return cleaned
  if (isTextarea) {
    return (
      text
        .replace(/\&nbsp;/g, ' ')
        // .replace(/^\s+|\s+$/gm, '')
        .replace('<div><br></div>', ' ')
        .replace(/<[^>]*>?/gm, '')
    );
  }
  return (
    text
      .replace(/\&nbsp;/g, ' ')
      //.replace(/^\s+|\s+$/gm, '')
      .replace('<div><br></div>', ' ')
      .replace(/<[^>]*>?/gm, '')
  );
  //.trim();
  //.trim();
};

export const navigateUrl = (url: string) => {
  window.open(url, 'blank');
};

export const DeleteClientCookie = (cookieName: string) => {


  document.cookie = `${cookieName}= ;path =/;domain= .voicebrief.app; expires = Thu, 01 Jan 1970 00:00:00 GMT`


}

export const numberFormatter = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}
